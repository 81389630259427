import React from 'react';
import styles from './home.module.scss';
import { Button } from 'react-bootstrap';
import Image from '../../assets/images/image.svg';
import RightArrow from '../../assets/icons/rightArrow';


const Home = () => {
  return (
    <>
      <div className={styles.rippleBackground}>
        <div className={`${styles.circle} ${styles.xxlarge} ${styles.shade1}`}></div>
        <div className={`${styles.circle} ${styles.xlarge} ${styles.shade2}`}></div>
        <div className={`${styles.circle} ${styles.large} ${styles.shade3}`}></div>
      </div>
    
      <div className={`${styles.wrapper}`}>
        <p className={`${styles.title} h1 text-center fw-bold`} data-aos="fade-left">AI-powered platform streamlines</p>
        <p className={`${styles.title} h1 text-center fw-bold`} data-aos="fade-right">video creation in minutes</p>
        <p className='fs-3 text-center mt-3' data-aos="fade-up" data-aos-duration="400">Transform your knowledge into engaging explainer videos and courses</p>
        <div className="d-flex justify-content-center z-3">
          <Button href='https://app.feedio.ai/account/create-account' className={`shadow-1 ${styles.btnColor}`} variant="" active={false} data-aos="fade-up" data-aos-duration="500">
            <span className='me-2 fw-semibold'>Get Started Now</span>
            <RightArrow/>
          </Button>
        </div>
        <div className={styles.siteImage} >
          <img  src={Image} alt='img' data-aos="zoom-out"/>
        </div>
      </div>
    </>
  );
};

export default Home;
