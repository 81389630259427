import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import styles from './audience.module.scss';
import SideImage from '../../assets/images/Group.png';
import FillDots from '../../assets/icons/fillDots';
import GreenDots from '../../assets/icons/greenDots';
import Tick from '../../assets/icons/tick';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Audience = () => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // Video data
  const clientVideos = [
    {
      id: 1,
      thumbnail: require('../../assets/images/eden.png'),
      videoUrl: 'https://www.youtube.com/embed/k0qRltm6J2o?si=pxd1YfwSt4J6VF6H',
      title: 'EDEN'
    },
    {
      id: 2,
      thumbnail: require('../../assets/images/SMJ.png'),
      videoUrl: 'https://www.youtube.com/embed/ME4zoMZJpmE?si=FwShBKyLssOHn4TF',
      title: 'SMJ Marble'
    },
    {
      id: 3,
      thumbnail: require('../../assets/images/kwapio.png'),
      videoUrl: 'https://www.youtube.com/embed/GDvrjLHCZBs?si=rSnISHuk36CTTe13',
      title: 'Kwapio'
    },
    {
      id: 4,
      thumbnail: require('../../assets/images/pitchwork.png'),
      videoUrl: 'https://www.youtube.com/embed/49Skt2wHMAQ?si=1cecnu9VZmu8pNJu',
      title: 'Pitchworks'
    }
  ];

  // Open video modal
  const handleThumbnailClick = (video) => {
    setSelectedVideo(video);
    setShowVideoModal(true);
  };

  // Close video modal
  const handleModalClose = () => {
    setSelectedVideo(null);
    setShowVideoModal(false);
  };

  return (
    <Container className={`${styles.wrapper} py-5`}>
      <Row>
        <Col className='ps-xl-5 pt-xl-5 ps-lg-5 pt-lg-5 ps-md-5 ps-4' data-aos="zoom-in-right" data-aos-duration="600">
          <Row data-aos="fade-up" data-aos-duration="500">
            <p className={`${styles.fontColor} h5`}>Target Audience </p>
          </Row>
          <p className='h3' data-aos="fade-up" data-aos-duration="550">Who We Serve</p>
          <p className='fw-medium lh-lg w-xl-75' data-aos="fade-up" data-aos-duration="600">
            For those who share or want to share Knowledge. You have the expertise. You know your subject inside and
            out. Now imagine turning that knowledge into professional videos as easily as having a conversation.
          </p>
          <p className='h3' data-aos="fade-up" data-aos-duration="550">Perfect for</p>
          <p className='h6 mt-3'>
            <Tick />
            <span className='ms-3'>Educators crafting crystal-clear explanations</span>
          </p>
          <p className='h6 mt-3'>
            <Tick />
            <span className='ms-3'>Experts crafting compelling product demos</span>
          </p>
          <p className='h6 mt-3'>
            <Tick />
            <span className='ms-3'>Creators designing immersive courses</span>
          </p>
          <p className='h6 mt-3'>
            <Tick />
            <span className='ms-3'>Subject Matter Experts scaling their impact</span>
          </p>
          <p className='h6 mt-3'>
            <Tick />
            <span className='ms-3'>Teams delivering consistent, professional training</span>
          </p>
          <p
            className='fw-medium lh-lg w-xl-75'
            data-aos="fade-up"
            data-aos-duration="600"
            style={{ marginTop: '20px' }}
          >
            Whether you&apos;re explaining complex concepts, launching online courses, or creating product tutorials -
            we&apos;ve simplified professional video creation to match the way you naturally share knowledge. Because
            your expertise deserves to be seen, not stuck in editing.
          </p>
        </Col>
        <Col xl="6">
          <div className={styles.sideImage} data-aos="zoom-in" data-aos-duration="500">
            <span className={styles.fillDots}>
              <FillDots color={'#FDBA09'} />
            </span>
            <span className={styles.greenDots}>
              <GreenDots color={'#3E66F3'} />
            </span>
            <span className={styles.borderBg}></span>
            <img src={SideImage} alt='img' />
          </div>
        </Col>
      </Row>
      {/* New Section for Client Videos */}
      <Row className="mt-5">
        <p className="h3 text-center mb-4" data-aos="fade-up" data-aos-duration="500">Our Client Videos</p>
        {clientVideos.map((video) => (
          <Col lg={3} md={6} sm={12} key={video.id} className="mb-4" data-aos="fade-up" data-aos-duration="600">
            <div className={styles.videoCard} onClick={() => handleThumbnailClick(video)}>
              <img
                src={video.thumbnail}
                alt={video.title}
                className="img-fluid rounded"
                style={{ cursor: 'pointer', borderRadius: '8px', textAlign: 'center', height: 'auto' }}
              />
              <p className="mt-2 text-center">{video.title}</p>
            </div>
          </Col>
        ))}
      </Row>

      {/* Video Modal */}
      <Modal show={showVideoModal} onHide={handleModalClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedVideo?.title || 'Client Video'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedVideo && (
            <iframe
              src={selectedVideo.videoUrl}
              style={{ width: '100%', height: '315px' }}
              title={selectedVideo.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Audience;
