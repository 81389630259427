import React from 'react';
import { Container, Nav } from 'react-bootstrap';
import Insta from '../../assets/icons/insta';
import Twitter from '../../assets/icons/twitter';
import FaceBook from '../../assets/icons/fb';
import YouTube from '../../assets/icons/yt';
import Ticktok from '../../assets/icons/ticktok';
import CookieConsent from '../cookie/cookieConsent';

const Footer = () => {
  return (
    <div style={{backgroundColor:'#212529'}}>
      <Container className='pt-4' style={{backgroundColor:'#212529'}}>
        <p className='h3 text-center text-light'>Feedio.ai</p>
        <div className='d-md-flex justify-content-center gap-5'>
          <Nav.Link href="#home" className='fw-semibold text-light py-3'>Home</Nav.Link>
          {/* <Nav.Link href="#solution" className='fw-semibold text-light py-3'>About us</Nav.Link>
          <Nav.Link href="#audience" className='fw-semibold text-light py-3'>Services</Nav.Link> */}
          <Nav.Link href="/use-cases" className='fw-semibold text-light py-3'>Use Cases</Nav.Link>
          <Nav.Link href="#footer" className='fw-semibold text-light py-3'>Contact us</Nav.Link>
          <Nav.Link href="https://app.feedio.ai/account/login" className='fw-semibold text-light py-3'>Login</Nav.Link>
        </div>
        <div className='d-flex justify-content-center gap-md-3 gap-3 pb-3'>
          <p className='h6 text-center pt-md-4'><Insta/></p>
          <p className='h6 text-center pt-md-4'><Twitter/></p>
          <p className='h6 text-center pt-md-4'><FaceBook/></p>
          <p className='h6 text-center pt-md-4'><YouTube/></p>
          <p className='h6 text-center pt-md-4'><Ticktok/></p>
        </div>
        <div>
          <Container className='pt-4' style={{ backgroundColor: '#212529' }}>
            <hr style={{ borderColor: '#555', margin: '0 0 10px 0' }} />
            <div className='d-flex justify-content-between align-items-center pb-3'>
              <p className='p text-white mb-0' style={{ fontSize: '0.8rem' }}>© Copyright 2024 Feedio AI, all rights reserved.</p>
              <div className='d-flex gap-4'>
                <a href="mailto:info@techjedi.in" className='text-light fw-normal text-decoration-none' style={{ fontSize: '0.8rem' }}>
                  support@feedio.in
                </a>
                <a href="/terms-of-use" className='text-light fw-normal text-decoration-none' style={{ fontSize: '0.8rem' }}>
                  Terms of Use
                </a>
                <a href="/privacy-policy" className='text-light fw-normal text-decoration-none' style={{ fontSize: '0.8rem' }}>
                  Privacy Policy
                </a>
              </div>
            </div>
          </Container>
        </div>
      </Container>
      <CookieConsent />
    </div>
  );
};

export default Footer;
