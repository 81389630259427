import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import styles from './page.module.scss';
import Home from './home';
import Challenges from './challenges';
import Solution from './solution';
import Features from './features';
import Audience from './audience';
import ScrollIndicator from './scrollIndicator';
import { useEffect, useState } from 'react';
import Price from './price';
import Quotation from '../assets/icons/quotation';
import GreenDots from '../assets/icons/greenDots';
import FillDots from '../assets/icons/fillDots';
import Process from './process';
import Footer from './footer';
import AppLogo from '../assets/icons/applogo';

function Website() {
  const [progressWidth, setProgressWidth] = useState();

  useEffect(() => {
    const handleScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      setProgressWidth(scrolled);

    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <div className={`${progressWidth>2 && "bg-body-tertiary"} position-sticky top-0 z-3`}>
        <ScrollIndicator progressWidth={progressWidth}/>
        <Navbar expand="lg" className={`${styles.navText} z-3 position-sticky top-0`}>
          <Container>
            <Navbar.Brand href="#" className={`${styles.navText} ${styles.appLogo}`}>
              <AppLogo height={45} width={250} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" className="ms-auto" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="ms-auto my-2 my-lg-0 mx-auto gap-3" style={{ maxHeight: '270px' }}>
                <Nav.Link href="#home" className="fw-semibold">Home</Nav.Link>
                {/* <Nav.Link href="#solution" className="fw-semibold">About us</Nav.Link>
                <Nav.Link href="#audience" className="fw-semibold">Services</Nav.Link> */}
                <Nav.Link href="/use-cases" className="fw-semibold">Use Cases</Nav.Link>
                <Nav.Link href="#contact" className="fw-semibold">Contact us</Nav.Link>
                <Nav.Link href="https://app.feedio.ai/account/login" className="fw-semibold">Login</Nav.Link>
              </Nav>
              <Button href='https://app.feedio.ai/account/create-account' className={`shadow-1 ${styles.cusbtn}`}>Start Free Trial</Button>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <div className={styles.homeWrapper} id='home'>
        <Home/>
      </div>

      <div className={styles.challengesWrapper}>
        <Challenges/>
      </div>

      <div className={styles} id='solution'>
        <Solution/>
      </div>

      <div className={styles.audienceWrapper} id='audience'>
        <Audience/>
      </div>

      <div className={`${styles.missionWrapper}`} id='ourmission'>
        <div className={`${styles.missionSubWrapper}`}>
          <Row className='p-md-5 ps-2'>
            <span className={`${styles.quotationOne} position-absolute`}><Quotation/></span>
            <Col className='align-self-center' md={5}>
              <p className='h2'>Our Mission</p>
            </Col>
            <Col>
              <p className={`p-md-0 ${styles.text}`}>To empower content creators with cutting-edge Gen-AI technology, enabling them to develop superior video content that educates and captivates their audiences.</p>
            </Col>
            <span className={`${styles.greenDots} position-absolute overflow-hidden`}><GreenDots color={"#03C7FD"}/></span>
            <span className={`${styles.quotationTwo} position-absolute overflow-hidden`}><Quotation/></span>
            <span className={`${styles.fillDots} position-absolute overflow-hidden`}><FillDots color={"#FDBA09"}/></span>
          </Row>
        </div>
      </div>

      <div className={styles.featureWrapper} id='features'>
        <Features/>
      </div>

      <div className={styles.processWrapper}>
        <Process/>
      </div>

      <div className={styles.priceWrapper} id='contact'>
        <Price/>
      </div>

      <div className={styles.footerWrapper} id='footer'>
        <Footer/>
      </div>

    </div>
  );
}

export default Website;
